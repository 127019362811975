<template>
  <div class="home">
    <a-scene 
			embedded
			vr-mode-ui="enabled: false"
			arjs="sourceType: webcam; debugUIEnabled: false;"
		>
			<a-assets>
				<video ref="myVideo" id="ar-video" crossOrigin="anonymous" type="video/mp4"
					src="https://ceshi.test.mryt.vip/37e69ff5bb44a593cb486e03c8179cb8.mp4" muted autoplay loop="true" playsinline webkit-playsinline></video>
			</a-assets>
			<a-marker type='pattern' url='https://ceshi.test.mryt.vip/pattern-marker.patt' registerevents>
				<a-video src="#ar-video" width="1" height="1" scale="1 1 1" position="0 0 0" rotation="270 0 0" material="shader: flat"></a-video>
			</a-marker>
			<a-entity camera></a-entity>
		</a-scene>
  </div>
</template>

<script>
// @ is an alias to /src
import * as ARjs from '@ar-js-org/ar.js';

export default {
  name: 'HomeView',
  data() { 
        return {
            video: null
        }
		},
		mounted() {
        let self = this;
			self.$nextTick(()=>{
				self.video = self.$refs.myVideo;
				self.video.muted = true; // 页面加载时确保视频静音
				AFRAME.registerComponent('registerevents', {
					init: function () {
						var marker = this.el;
						
						if (!self.video) {
							self.video = self.$refs.myVideo;
						}
					
						marker.addEventListener('markerFound', function () {
							self.video.muted = false; // 取消静音
							self.$refs.myVideo.play();
						});
					
						marker.addEventListener('markerLost', function () {
							self.video.muted = true; // 恢复静音，以防止再次自动播放时有声音
							self.$refs.myVideo.pause();
						});
						
					}
				});
			})
    },
}
</script>

<style lang="less" scoped>
	.home{
		width: 100vw;
		height: 100vh;
	}
</style>
